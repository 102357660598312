import React from 'react'

export default function Footer() {
    return (
        <div className='footer position-sticky' style={{width:'100%',height:'100px',backgroundColor:'#e3f2fd',display:'flex',justifyContent:'center',alignItems:'center'}}>
            <div className='text-primary'>© e-sakal, All rights reserved.</div>
        </div>
    )
}

