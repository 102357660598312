import React from 'react'
// import { useSearchParams } from 'react-router-dom';
import img from '../asset/home.png'

export default function SendMsg() {
    const [cities, setCities] = React.useState([])
    React.useEffect(() => {
        getCities();
    }, [])


    const alert = (message, type, element) => {
        const wrapper = document.createElement('div')
        wrapper.innerHTML = [
            `<div class="alert alert-${type} alert-dismissible" role="alert">`,
            `   <div>${message}</div>`,
            '   <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>',
            '</div>'
        ].join('')

        element.append(wrapper)
    }
    const getCities = async () => {
        await fetch("https://yin-api.foxberry.link/v1/address/city/MH", {
            method: 'GET',
            header: { 'Content-Type': 'application/json' }
        },)
            .then((response) => response.json())
            .then((data) => {
                // console.log('Success:', data[0].name);
                setCities(data)
            })
    }
    const checkMobile = (value, element) => {
        console.log("PATTERN ", value.match(/^\d{10}$/))
        if (value.match(/^\d{10}$/)) {
            return true;
        }
        else {
            element.innerHTML = "";
            alert("Please Enter Proper Mobile Number !", "danger", element);
            return false;
        }
    }
    const onSubmit = async (e) => {
        e.preventDefault();
        let bool = false;
        const alertPlaceholder = document.getElementById('liveAlertPlaceholder');
        let mobileNumber = document.forms["form1"].mobile.value
        let selectedOption = document.forms["form1"].city.value
        if (selectedOption === "choose") {
            alertPlaceholder.innerHTML = "";
            alert('Please Select District !', 'danger', alertPlaceholder);
            bool = false;
        }
        else {
            console.log("TYPE OF ", typeof (mobileNumber))
            bool = checkMobile(mobileNumber, alertPlaceholder);
        }
        if (bool) {
            let payload = {
                contactNo: mobileNumber,
                city: selectedOption,
            }
            console.log("Payload", payload)

            let urlencoded = new URLSearchParams();
            urlencoded.append("contactNo", mobileNumber)
            urlencoded.append("city", selectedOption)

            await fetch("https://esakal-api.foxberry.link/v1/sakal/insertsakalcontactno", {
                method: 'POST',
                header: { 'Content-Type': 'application/x-www-form-urlencoded' },
                body: urlencoded
            },
            )
                .then((response) => {
                    // console.log("first",response);
                    response.json();
                    if (response.status === 200) {
                        window.location.href = `https://wa.me/9420825595?text= वरील क्रमांक 'Sakal' नावाने सेव्ह केल्यानंतर आपली सेवा त्वरित सुरु करण्यात येईल.`
                    }
                    else {
                        alertPlaceholder.innerHTML = "";
                        alert('Server Error Please Try Again !', 'danger', alertPlaceholder);
                    }
                }).then(res=>{
                    console.log("Response::::",res)
                }).catch(e=>console.log("ERROR",e))
        }
        else {
            return
        }
    }

    let opt = cities.length && cities.map((item) => <option value={item.name} key={item._id}>{item.name}</option>)

    return (
        <div className='sndMsg'>
            {/* // eslint-disable-next-line */}
            <img src={img} alt="Group" className='grpImg' />
            <div>
                <form id="form1" onSubmit={(e) => { onSubmit(e); }} >
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <label className="" htmlFor="inputGroupSelect01" style={{ width: '100%', margin: '0px 20px' }}>Select District</label>
                        </div>
                        <select className="custom-select" id="inputGroupSelect01" name="city" style={{ width: '100%', height: '50px', margin: '0px 20px' }} required>
                            <option value="choose">Choose...</option>
                            {opt}
                        </select>
                    </div>
                    <div>
                        <div className="input-group mb-3">
                            {/* <div className="input-group-prepend"> */}
                                <span className="" id="inputGroup-sizing-default" style={{ width: '100%', margin: '0px 20px' }}>WhatsApp Number</span>
                            {/* </div> */}
                            {/* <div> */}
                                <input type="text" className="form-control" name="mobile" aria-label="Default" maxLength={10} style={{ width: '100%',height:'50px', margin: '0px 20px' }} />
                            {/* </div> */}
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <button type="submit" className="btn btn-outline-success">WhatsApp Messege</button>
                    </div>
                </form>

            </div>
            <div id="liveAlertPlaceholder" className='m-5'></div>

        </div>
    )
}
