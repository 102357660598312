import React from 'react'
import walogo from '../asset/walogo.png'
import { useNavigate } from "react-router-dom"

export default function Section() {
    const navigate =  useNavigate()
    return (
        <div><section className="bg-half-170 pb-0 bg-light d-table w-100" style={{height:'73vh'}}>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-10">
                        <div className="title-heading text-center mt-4 mt-md-5 pt-md-5">

                            <h3 className="heading mb-2">
                                <span className="element text-primary"
                                    data-elements="ब्रेकिंग न्यूज, जॉब अपडेट्स, माहिती आणि मनोरंजन ,शॉपिंगची धमाल, स्पर्धा परीक्षा मार्गदर्शन "></span>
                                <br />
                                <span style={{ fontSize: "24px !important" }}>आता तुमच्या <span style={{ color: "#25D366" }}> <img src={walogo} className="logowa" alt="" />WhatsApp</span> वर</span>
                            </h3>


                            <p className="para-desc mx-auto text-muted" style={{ marginBottom: "10px !important" }}>
                                चला तर मग, आजच सकाळ जॉईन करा🤝
                            </p>

                            <div className="alert alert-pills" role="alert">

                                <span className="content"> Join Now, Absolutely </span>
                                <span className="badge badge-pill badge-success mr-1" style={{ backgroundColor: "#25D366" }}> Free</span>
                            </div>
                            <button type="button" className="btn btn-outline-primary" onClick={()=>navigate('/home')}>Proceed</button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </div>
    )
}
