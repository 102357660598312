
import './App.css';
import { Route, Routes } from 'react-router-dom';
import MainLayout from './components/MainLayout';
import Section from '../src/components/Section';
import SendMsg from './components/SendMsg';
import Records from './components/Records';

function App() {
  return (
    <div>
      <Routes>
        <Route path='/' element={<MainLayout />}>
          <Route path='/' element={<Section />} />
          <Route path='/home' element={<SendMsg />} />
          <Route path='/admin/records' element={<Records />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
