import React from 'react'
import sakalLogo from '../asset/sakalLogo.webp'

export default function NavBar() {
    return (
        <nav className="navbar " style={{backgroundColor:"#e3f2fd"}}>
            <div className="container">
                <a className="navbar-brand" href="/">
                    <img src={sakalLogo} alt="Bootstrap" width="auto" height="80" />
                </a>
            </div>
        </nav>
        
    )
}
