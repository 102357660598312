import React from 'react'
import * as XLSX from 'xlsx'

export default function Records() {
    const [record, setRecord] = React.useState([])
    const [totalRecords, setTotalRecords] = React.useState([])
    const [pages, setPages] = React.useState(0)
    const [endIndex, setEndIndex] = React.useState(10)
    const [startIndex, setStartIndex] = React.useState(0)
    const [count, setCount] = React.useState(1)
    React.useEffect(() => {
        getRecords();
    }, [])


    const getRecords = () => {
        fetch("https://sakalmoneyapp.foxberry.link/v1/sakal/getallcontactno", {
            method: "GET",
        })
            .then(res => res.json())
            .then(data => {
                console.log("Response", data)
                setTotalRecords(data);
                setPages(data.length / 10);
                setRecord(data.filter((item, index) => index >= 0 && index < 10));
                // console.log(data.filter((item,index)=>index>si&&index<=ei))
            })
            .catch(err => console.warn("Error", err))
    }
    const pagination = (si, ei) => {
        console.log("Start Index", startIndex)
        console.log("End Index", endIndex)
        console.log("Count ", count)
        setRecord(totalRecords.filter((item, index) => index >= si && index < ei));
    }
    const downloadExcel = (customHeadings) => {
        let dataSet = [];
        console.log("dataset", dataSet)
        dataSet = customHeadings;
        const worksheet = XLSX.utils.json_to_sheet(dataSet);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, "UserListReport.xlsx");
    };

    const CustomExcel = (data) => {

        const customHeadings = data.length > 0 && data.map((item, index) => ({
            "Sr No.": index + 1,
            "Date": item.createdAt ? `${item.createdAt.split('T')[0].split('-')[2]}/${item.createdAt.split('T')[0].split('-')[1]}/${item.createdAt.split('T')[0].split('-')[0]}` : '-',
            "Mobile": item.contactNo ? item.contactNo : '-',
            "City": item.city ? item.city : '-',
        }))
        console.log("Customm Excel", customHeadings)
        downloadExcel(customHeadings)
    };
    const renderTable = record.length > 0 && record !== undefined && record.map((item, index) => { return <tr key={index}><td>{index + 1}</td><td>{item.createdAt.split('T')[0].split('-')[2]}/{item.createdAt.split('T')[0].split('-')[1]}/{item.createdAt.split('T')[0].split('-')[0]}</td><td>{item.contactNo}</td><td>{item.city}</td></tr> })
    return (
        <div className='container p-5'>
            {/* Header */}
            <div className='d-flex flex-row justify-content-between'>
                <div style={{ fontSize: '28px', fontWeight: 'bold' }} >Records</div>
                <div></div>
                <div><button type="button" className="btn btn-outline-primary" onClick={() => CustomExcel(totalRecords)}>Export</button></div>
            </div>

            <div className="table-responsive">
                <table className="table table-hover">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Date</th>
                            <th>Mobile</th>
                            <th>City</th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderTable !== undefined ? renderTable : null}
                    </tbody>
                </table>
            </div>
            {/* Pagination */}
            <nav aria-label="Page navigation example">
                <ul className="pagination justify-content-center">
                    <li className={`page-item ${startIndex < 10 && endIndex < 20 ? 'disabled' : null}`}>
                        <a className="page-link" href="#" tabIndex="-1" onClick={() => {
                            setStartIndex(startIndex - 10); setEndIndex(endIndex - 10); setCount(count - 1); pagination(startIndex - 10, endIndex - 10);
                        }}>Previous</a>
                    </li>
                    {(() => {
                        let a = []
                        for (let i = 0; i < pages; i++)
                            a.push(<li key={i} className={`page-item ${count === i + 1 ? 'disabled' : null}`}><a className="page-link" href="#" onClick={() => { setStartIndex(10 * i); setEndIndex(10 * (i + 1)); setCount(i + 1); pagination(10 * i, (10 * (i + 1))); }}>{i + 1}</a></li>)
                        return a;
                    })()}
                    {/* <li className="page-item"><a className="page-link" href="#">1</a></li>
                    <li className="page-item"><a className="page-link" href="#">2</a></li>
                    <li className="page-item"><a className="page-link" href="#">3</a></li> */}
                    <li className={`page-item ${startIndex >= (pages * 10 - 10) && endIndex >= (pages * 10) ? 'disabled' : null}`}>
                        <a className="page-link" href="#" onClick={() => { setStartIndex(startIndex + 10); setEndIndex(endIndex + 10); setCount(count + 1); pagination(startIndex + 10, endIndex + 10); }}>Next</a>
                    </li>
                </ul>
            </nav>
            <span className=''>{`Record Count ${totalRecords.length}`}</span>
        </div>
    )
}
